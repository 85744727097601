import React, { useRef, useContext } from 'react'
import styled from 'styled-components'
import gsap from 'gsap'
import { ScreenContext } from 'components/Providers'

import colors from 'styles/colors'
import text from 'styles/text'
import media from 'styles/media'

import { useMedia } from 'utils/hooks'
import links from 'utils/links'

import { ReactComponent as SaveSVG } from "assets/images/icons/download.svg";
import { ReactComponent as GoogleSVG } from 'assets/images/icons/google.svg';
import { ReactComponent as AppleSVG } from 'assets/images/icons/apple.svg'

type Props = {
  long?: boolean
}

const Download: React.FC<Props> = ({ long }) => {
  const screen = useContext(ScreenContext)

  const outlineRef = useRef<SVGPathElement | null>(null)
  const contentRef = useRef<HTMLDivElement | null>(null)
  const leftContentRef = useRef<HTMLDivElement | null>(null)
  const rightContentRef = useRef<HTMLDivElement | null>(null)
  const leftOutlineRef = useRef<SVGPathElement | null>(null)
  const rightOutlineRef = useRef<SVGPathElement | null>(null)
  const vrRef = useRef<HTMLDivElement | null>(null)

  const vrHeight = useMedia('3.13vw', '3.13vw', '4.49vw', '3.13vw')
  
  const contentClick = () => {
    const tl = gsap.timeline()

    tl.set(contentRef.current, {
      zIndex: 0
    }, 0)

    tl.to(contentRef.current, {
      duration: 0.2,
      y: '100%'
    }, 0)

    tl.to(outlineRef.current, {
      duration: 0.2,
      fill: 'transparent'
    }, 0)

    tl.to(vrRef.current, {
      duration: 0.5,
      height: vrHeight
    }, 1)

    tl.to([leftContentRef.current, rightContentRef.current], {
      duration: 0.5,
      opacity: 1
    }, 1)
  }
  
  const contentMouseEnter = () => {
    if (!screen.tablet && !screen.mobile) {
      gsap.to(outlineRef.current, {
        duration: 0.5,
        fill: colors.pureWhite
      })
    } 
  }

  const contentMouseLeave = () => {
    gsap.to(outlineRef.current, {
      duration: 0.5,
      fill: 'transparent'
    })
  }

  const rightContentMouseEnter = () => {
    gsap.to(rightOutlineRef.current, {
      duration: 0.5,
      fill: colors.pureWhite
    })
  }

  const rightContentMouseLeave = () => {
    gsap.to(rightOutlineRef.current, {
      duration: 0.5,
      fill: 'transparent'
    })
  }

  const leftContentMouseEnter = () => {
    gsap.to(leftOutlineRef.current, {
      duration: 0.5,
      fill: colors.pureWhite
    })
  }

  const leftContentMouseLeave = () => {
    gsap.to(leftOutlineRef.current, {
      duration: 0.5,
      fill: 'transparent'
    })
  }

  const linkClick = (href: string) => {
    window.open(href)
  }

  return (
    <Wrapper long={long}>
      <Content 
        ref={contentRef}
        onClick={contentClick} 
        onMouseEnter={contentMouseEnter} 
        onMouseLeave={contentMouseLeave}
      >
        <Save/> Download the app
      </Content>

      <HalfContent 
        ref={leftContentRef} 
        onClick={() => linkClick(links.google)}
        onMouseEnter={leftContentMouseEnter}
        onMouseLeave={leftContentMouseLeave}
      >
        <Google/>
      </HalfContent>

      <VR ref={vrRef}/>

      <HalfContent 
        ref={rightContentRef}
        onClick={() => linkClick(links.apple)}
        onMouseEnter={rightContentMouseEnter}
        onMouseLeave={rightContentMouseLeave}
      >
        <Apple/>
      </HalfContent>

      {!long && <SVG viewBox="0 0 240 56" preserveAspectRatio="none">
        <HalfOutline 
          ref={rightOutlineRef}
          d="M 120 0.5 H 225 L 239.5 15 V 55 H 120 Z"
        />
        <HalfOutline
          ref={leftOutlineRef}
          d="M 0.5 0.5 H 120.5 V 55 H 15 L 0.5 41 Z"
        />
        <Outline 
          ref={outlineRef}
          strokeWidth="1px"
          d="M 0.5 0.5 H 225 L 239.5 15 V 55 H 15 L 0.5 41 Z"
        />
      </SVG>}

      {long && !screen.mobile && <SVG viewBox="0 0 904 56">
        <HalfOutline 
          ref={rightOutlineRef}
          d="M 452 0.5 H 904 L 903.5 15 V 55 H 452 Z"
        />
        <HalfOutline
          ref={leftOutlineRef}
          d="M 0.5 0.5 H 452.5 V 55 H 15 L 0.5 41 Z"
        />
        <Outline 
          ref={outlineRef}
          strokeWidth="1px"
          d="M 0.5 1 H 889 L 903.5 15 V 55 H 15 L 0.5 41 Z"
        />
      </SVG>}

      {screen.mobile && <SVG viewBox="0 0 345 56">
        <HalfOutline 
          ref={rightOutlineRef}
          d="M 452 0.5 H 345 L 344.5 15 V 55 H 452 Z"
        />
        <HalfOutline
          ref={leftOutlineRef}
          d="M 0.5 0.5 H 452.5 V 55 H 15 L 0.5 41 Z"
        />
        <Outline 
          ref={outlineRef}
          strokeWidth="1px"
          d="M 0.5 0.5 H 335 L 344.5 15 V 55 H 15 L 0.5 41 Z"
        />
      </SVG>}

    </Wrapper>
  )
}

export default Download

const SVG = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  fill: none;
`

const Outline = styled.path`
  stroke: ${colors.pureWhite};
  stroke-width: 1px;
`

const HalfOutline = styled.path`
  backdrop-filter: blur(1px);
  stroke-width: 0px;
`

const Save = styled(SaveSVG)`
  path {
    transition: 500ms;
  }

  width: 1.74vw;
  height: 1.74vw;
  margin-right: 1.04vw;

  ${media.tablet} {
    height: 1.95vw;
    width: 1.95vw;
    margin-right: 1.71vw;
  }
  ${media.mobile} {
    width: 6.667vw;
    height: 6.667vw;
    margin-right: 4vw;
  }
`;


const Content = styled.div`
  width: fit-content;
  position: absolute;
  z-index: 2;
  background: transparent;
  white-space: pre;
  color: ${colors.pureWhite};
  transition: 500ms;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;

  &:hover {
    color: ${colors.pureBlack};
    
    path {
      stroke: ${colors.pureBlack};
    }
  }

  border-radius: 1px;
`;

const Wrapper = styled.div<{ disabled?: boolean, long?: boolean }>`
  ${text.desktop.button}
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border-radius: 1px;
  transform: translate3d(0, 0, 0);

  &:active {
    opacity: 0.9;
  }

  width: 16.67vw;
  height: 3.89vw;

  ${media.tablet} {
    ${text.tablet.button}
    width: ${props => props.long ? '88.28vw' : '19.73vw'};
    height: 5.47vw;
  }

  ${media.mobile} {
    ${text.mobile.button}
    height: 14.933vw;
    width: 92vw;
  }
`;

const VR = styled.div`
  width: 1px;
  background: ${colors.pureWhite};
  opacity: 0.2;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  
  top: 0.35vw;
  height: 0vw;

  ${media.tablet} {
    width: 2px;
    top: 0.49vw;
  }
  ${media.mobile} {
    width: 2px;
    top: 5vw;
  }
`

const Google = styled(GoogleSVG)`
  width: 2.08vw;
  height: 2.08vw;

  ${media.tablet} {
    width: 2.93vw;
    height: 2.93vw;
  }

  ${media.tablet} {
    width: 2.93vw;
    height: 2.93vw;
  }
  ${media.mobile} {
     width: 6vw;
     height: 6vw;
  }
`

const Apple = styled(AppleSVG)`
  width: 2.08vw;
  height: 2.08vw;

  ${media.tablet} {
    width: 2.93vw;
    height: 2.93vw;
  }
  ${media.mobile} {
     width: 6vw;
     height: 6vw;
  }
`

const HalfContent = styled.div`
  display: flex;
  opacity: 0;
  height: 100%;
  width: 50%;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;

  path {
    transition: 500ms;
    fill: ${colors.pureWhite};
  }

  ${media.hover} {
    &:hover {
      path {
        fill: ${colors.pureBlack};
      }
    }
  }
`
