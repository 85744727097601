import React, { useEffect, useState, createContext, Dispatch, SetStateAction, useCallback } from "react";
import { desktop, tablet, mobile } from "styles/media";

type CursorContextType = {
  value: { roadmap: boolean; banners: boolean };
  setValue: Dispatch<SetStateAction<{ roadmap: boolean; banners: boolean }>> | Function;
};

type PreloaderContextType = {
  value: number;
  setValue: Dispatch<SetStateAction<number>> | Function;
};

export const ScreenContext = createContext({
  fullWidth: false,
  desktop: false,
  tablet: false,
  mobile: false,
});

export const CursorContext = createContext<CursorContextType>({
  value: { roadmap: false, banners: false },
  setValue: () => ({ roadmap: false, banners: false }),
});

export const FontsLoadedContext = createContext<boolean>(false)

export const PreloaderContext = createContext<PreloaderContextType>({
  value: 0,
  setValue: () => false,
})

const Providers: React.FC = ({ children }) => {
  const [screen, setScreen] = useState({
    fullWidth: false,
    desktop: false,
    tablet: false,
    mobile: false,
  });

  const [cursorVisible, setCursorVisible] = useState({ roadmap: false, banners: false });

  const [fontsLoaded, setFontsLoaded] = useState<boolean>(false)

  const [preloaderPerc, setPreloaderPerc] = useState<number>(0)

  const handlePreloaderPerc = useCallback((val: number) => {
    setPreloaderPerc(perc => perc + val)
  }, [setPreloaderPerc])

  useEffect(() => {
    document.fonts.ready.then((set) => {
      setFontsLoaded(true)
      handlePreloaderPerc(20)
    })

  }, [handlePreloaderPerc])

  useEffect(() => {
    const calcScreen = () => {
      setScreen({
        fullWidth: window.innerWidth > desktop,
        desktop: window.innerWidth > tablet && window.innerWidth <= desktop,
        tablet: window.innerWidth > mobile && window.innerWidth <= tablet,
        mobile: window.innerWidth <= mobile,
      });
    };

    calcScreen();

    window.addEventListener("resize", calcScreen);

    return () => {
      window.removeEventListener("resize", calcScreen);
    };
  }, []);

  return (
    <ScreenContext.Provider value={screen}>
      <PreloaderContext.Provider value={{
        value: preloaderPerc,
        setValue: handlePreloaderPerc
      }}>
        <CursorContext.Provider
          value={{
            value: cursorVisible,
            setValue: setCursorVisible,
          }}>
          <FontsLoadedContext.Provider value={fontsLoaded}>
            {children}
          </FontsLoadedContext.Provider>
        </CursorContext.Provider>
      </PreloaderContext.Provider>
    </ScreenContext.Provider>
  );
};

export default Providers;
