import React, { useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import gsap from "gsap";

import { vwToPx } from "utils/functions";
import { useMedia } from "utils/hooks";

import colors from "styles/colors";
import text from "styles/text";

import { CursorContext } from "components/Providers";

import socialCursor from "assets/images/socialCursor.png";

const Cursor: React.FC = () => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const cursorWidth = useMedia(5.21, 5.21, 5.21, 5.21);

  const cursor = useContext(CursorContext);

  useEffect(() => {
    const mouseMove = (e: MouseEvent) => {
      gsap.to(wrapperRef.current, {
        duration: 0.2,
        x: e.clientX - vwToPx(cursorWidth / 2),
        y: e.clientY - vwToPx(cursorWidth / 2),
      });
    };

    window.addEventListener("mousemove", mouseMove);

    return () => {
      window.removeEventListener("mousemove", mouseMove);
    };
  }, [cursorWidth]);

  return (
    <Wrapper ref={wrapperRef}>
      <SocialCursor visible={cursor.value.banners} src={socialCursor} loading="lazy" alt="cursor"/>
      <RoadMapCursor visible={cursor.value.roadmap}>{"< DRAG >"}</RoadMapCursor>
    </Wrapper>
  );
};

export default Cursor;

const Wrapper = styled.div`
  position: fixed;
  z-index: 5;

  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  cursor: none;
  width: 5.21vw;
  height: 5.21vw;
`;

const RoadMapCursor = styled.div<{ visible: boolean }>`
  position: absolute;
  ${text.desktop.accent3}
  color: ${colors.pureWhite};
  text-align: center;
  display: flex;
  align-items: center;
  background: ${colors.horzGradient};
  width: 100%;
  height: 100%;
  line-height: 100%;
  border-radius: 100%;
  justify-content: center;
  border: 1px solid ${colors.pureBlack};
  opacity: ${(props) => (props.visible ? 1 : 0)};
  cursor: none;
  pointer-events: none;
  transition: opacity 0.5s;

  box-shadow: 1.39vw 2.78vw 5.56vw rgba(0, 0, 0, 0.6);
`;

const SocialCursor = styled.img<{ visible: boolean }>`
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 0.5s;
  z-index: 2;
  cursor: none;
  pointer-events: none;

  border-radius: 100%;
  box-shadow: 1.39vw 2.78vw 5.56vw rgba(0, 0, 0, 0.6);
`;
