const links = {
  home: "https://freshcut.gg/",
  discord: "https://discord.com/invite/freshcut",
  twitter: "https://twitter.com/freshcut",
  instagram: "https://www.instagram.com/freshcutgg/",
  medium: "https://medium.com/@freshcut",
  apple: "https://apps.apple.com/us/app/id1508208562",
  google: "https://play.google.com/store/apps/details?id=gg.freshcut.prod",
  litepaper: "https://docsend.com/view/jh9tqn6wm8gw84g3",
  sale: "https://republic.com/freshcut",
  polkastarter: "https://polkastarter.com/projects/freshcut",
  telegram: "https://t.me/freshcutdiamond",
  tokencontract: "https://polygonscan.com/token/0xf78610D0a197842bF98ca45254897edd13c5D182",
  getfcd: "https://freshcut.gitbook.io/freshcut/freshcut-diamonds-usdfcd/can-the-token-be-bought-or-sold-on-the-matic-network",
  faq: "https://freshcut.gitbook.io/freshcut/",
  press: "https://freshcut.gitbook.io/freshcut/freshcut-news/press",
  creators: "https://creators.freshcut.gg",
}

export default links