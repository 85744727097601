import React, { useContext, useCallback, useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import gsap from 'gsap'
import { PreloaderContext } from './Providers'

import media from 'styles/media'

import Video0MP4 from "assets/videos/carousel/video-0.mp4";
import Video1MP4 from "assets/videos/carousel/video-1.mp4";

const NUM_VIDEOS = 8

const VideoCarousel: React.FC = () => {

  const importedVideos = useRef<string[]>([Video0MP4, Video1MP4])
  const setPreloaderPerc = useContext(PreloaderContext).setValue

  const [video1Ref, setVideo1Ref] = useState<HTMLVideoElement | null>(null)
  const [video2Ref, setVideo2Ref] = useState<HTMLVideoElement | null>(null)
  const [video3Ref, setVideo3Ref] = useState<HTMLVideoElement | null>(null)

  const handleCanPlayThrough = useCallback(() => {
    setPreloaderPerc(10)
  }, [setPreloaderPerc])

  const loadNextVideo = useCallback((index: number, ref: any) => {
    if (importedVideos.current[index]) {
      ref.setAttribute('src', importedVideos.current[index])
    } else {
      import(`assets/videos/carousel/video-${index}.mp4`)
        .then(video => {
          importedVideos.current[index] = video.default
          ref.setAttribute('src', video.default)
        })
        .catch(err => {
          console.error(err)
        })
    }
  }, [])

  const nextVideo = useCallback((ref1: any, ref2: any, index: number) => {
    ref2?.play()
    ref1.removeEventListener('ended', () => false)

    gsap.fromTo(ref1, {
      top: '0%'
    }, {
      duration: 1,
      top: "-100%"
    })

    gsap.fromTo(ref2, {
      top: '100%'
    }, {
      duration: 1,
      top: '0%',
      onComplete: () => {
        let newIndex: number

        if (index === NUM_VIDEOS-1) {
          newIndex = 0
        } else {
          newIndex = index + 1
        }

        loadNextVideo(newIndex, ref1)
        ref2.addEventListener('ended', function nextVideoHandler() {
          nextVideo(ref2, ref1, newIndex)
          ref2.removeEventListener('ended', nextVideoHandler)
        })
      }
    })
  }, [loadNextVideo])

  const initNextVideo = useCallback(() => {
    if (video1Ref && video2Ref && video3Ref) {
      video2Ref.play()

      gsap.to(video1Ref, {
        duration: 1,
        top: '-100%',
        onComplete: () => {
          gsap.set(video1Ref, {
            display: 'none'
          })
          video1Ref.removeEventListener('ended', initNextVideo)
        }
      })
  
      gsap.to(video2Ref, {
        duration: 1,
        top: '0%',
        onComplete: () => {
          loadNextVideo(2, video3Ref)
          video2Ref.addEventListener('ended', function nextVideoHandler () {
            nextVideo(video2Ref, video3Ref, 2)
            video2Ref.removeEventListener('ended', nextVideoHandler)
          })
        }
      })
    }
  }, [video1Ref, video2Ref, video3Ref, nextVideo, loadNextVideo])

  useEffect(() => {
    if (video1Ref) {
      video1Ref.addEventListener('ended', initNextVideo)

      return () => {
        video1Ref.removeEventListener('ended', initNextVideo)
      }
    }
  }, [video1Ref, initNextVideo])

  return (
    <Wrapper>
        <Video1
          ref={ref => setVideo1Ref(ref)}
          muted
          controls={false}
          autoPlay
          playsInline
          preload="true"
          onLoadStart={() => setPreloaderPerc(20)}
          onLoadedMetadata={() => setPreloaderPerc(20)}
          onLoadedData={() => setPreloaderPerc(20)}
          onCanPlay={() => setPreloaderPerc(10)}
          onCanPlayThrough={handleCanPlayThrough}
        >
          <source 
            src={Video0MP4+'#t=0.1'} 
            type="video/mp4" 
          />
        </Video1>
        <Video2
          ref={ref => setVideo2Ref(ref)}
          muted
          controls={false}
          playsInline
          preload="true"
        >
          <source 
            src={Video1MP4} 
            type="video/mp4" 
          />
        </Video2>
        <Video3
          ref={ref => setVideo3Ref(ref)}
          muted
          controls={false}
          playsInline
          preload="true"
        >
        </Video3>
    </Wrapper>
  )
}

export default VideoCarousel

const Wrapper = styled.div`
  height: 55.56vw;
  overflow: hidden;
  position: relative;
  z-index: 1;
  transform: translate3d(0, 0, 0);

  ${media.tablet} {
    height: 133.398vw;
  }

  ${media.mobile} {
    height: 216.533vw;
  }
`

const Video = styled.video`
  position: absolute;
  transform: translate3d(0, 0, 0);

  left: 15vw;
  height: 55.56vw;

  ${media.tablet} {
    height: 133.398vw;
    left: -50vw;
  }

  ${media.mobile} {
    height: 216.533vw;
    left: -70%;
  }
`;

const Video1 = styled(Video)`
  top: 0%;
`

const Video2 = styled(Video)`
  top: 100%;
`

const Video3 = styled(Video)`
  top: 100%;
`