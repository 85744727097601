import React, { useEffect, useState, useCallback, useContext, useRef } from 'react'
import styled from 'styled-components'
import gsap from 'gsap'
import { PreloaderContext, FontsLoadedContext, ScreenContext } from './Providers'

import colors from 'styles/colors'
import media from 'styles/media'

import DiamondMP4 from 'assets/videos/diamond.mp4'
import DiamondWEBP from 'assets/images/diamond.webp'

type Props = {
  setLoaded: Function
}

const Preloader: React.FC<Props> = ({ setLoaded }) => {

  const percentage = useContext(PreloaderContext).value
  const fontsLoaded = useContext(FontsLoadedContext)
  const screen  = useContext(ScreenContext)

  const animationComplete = useRef<boolean>(false)
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const wipeRef = useRef<HTMLDivElement | null>(null)
  const yellowRef = useRef<HTMLDivElement | null>(null)
  const whiteRef = useRef<HTMLDivElement | null>(null)
  const [circleRef, setCircleRef] = useState<SVGCircleElement | null>(null)
  const [bgRef, setBgRef] = useState<HTMLDivElement | null>(null)
  const [circleLength, setCircleLength] = useState<number>(1400)
  const [percLength, setPercLength] = useState<number>(0)

  const calcPercLength = useCallback((perc: number) => {
    return circleLength * (perc / 100)
  }, [circleLength])

  const calcCircleLength = useCallback(() => {
    if (circleRef) {
      let length = circleRef.getTotalLength()
      setCircleLength(length)
    }
  }, [setCircleLength, circleRef])

  const close = useCallback(() => {
    const tl = gsap.timeline({
      delay: 1,
      onComplete: () => {
        animationComplete.current = true
        setLoaded(true)
      }
    })

    tl.to(wipeRef.current, {
      duration: 0.5,
      height: '100%',
      ease: "circ.in"
    }, 0)

    tl.to(yellowRef.current, {
      duration: 0.5,
      height: '50vh',
      ease: "circ.in"
    }, 0)

    tl.to(whiteRef.current, {
      duration: 0.5,
      height: '50vh',
      ease: "circ.in"
    }, 0)

    tl.set(wipeRef.current, {
      top: 'unset',
      bottom: 0
    }, 0.5)

    tl.set(wrapperRef.current, {
      display: 'none'
    }, 0.5)

    tl.to(wipeRef.current, {
      duration: 0.5,
      height: '0%',
      ease: "circ.out"
    }, 0.5)

    tl.to(yellowRef.current, {
      duration: 0.5,
      height: '0vh',
      ease: "circ.out"
    }, 0.5)

    tl.to(whiteRef.current, {
      duration: 0.7,
      height: '0vh',
      ease: "circ.out"
    }, 0.5)
  }, [setLoaded])

  useEffect(() => {
    if (circleRef) {
      calcCircleLength()
    }
  }, [calcCircleLength, circleRef])

  useEffect(() => {
    if (bgRef) {
      let percLength = calcPercLength(percentage)
      setPercLength(percLength)
  
      gsap.set(bgRef, {
        height: `${100 - percentage}%`
      })
    }

    if (percentage === 100 && !animationComplete.current && fontsLoaded) {
      close()
    }
  }, [percentage, calcPercLength, bgRef, close, fontsLoaded])

  return (
    <>
      <Wipe ref={wipeRef}>
        <White ref={whiteRef}/>
        <Yellow ref={yellowRef}/>
      </Wipe>
      <Wrapper ref={wrapperRef}>
        <Content>
          {(screen.desktop || screen.fullWidth) && 
            <Video autoPlay muted controls={false} playsInline poster={DiamondWEBP}>
              <source src={DiamondMP4} type="video/mp4" />
            </Video>
          }
          {(screen.mobile || screen.tablet) && 
            <Image src={DiamondWEBP} alt="diamond"/>
          }
          <BG ref={ref => setBgRef(ref)}/>
          <SVG fill="none">
            <Circle ref={ref => setCircleRef(ref)} cx="50%" cy="50%" r="49%" strokeDasharray={`${percLength}, ${circleLength}`}/>
          </SVG>
        </Content>
      </Wrapper>
    </>
  )
}

export default Preloader

const Wrapper = styled.div`
  background: ${colors.pureBlack};
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`

const Content = styled.div`
  position: absolute;
  z-index: 5;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 17.36vw;
  height: 17.36vw;

  ${media.tablet} {
    width: 24.41vw;
    height: 24.41vw;
  }

  ${media.mobile} {
    width: 53.33vw;
    height: 53.33vw;
  }
`

const SVG = styled.svg`
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
`

const Circle = styled.circle`
  stroke: url(#verticalGradient);
  stroke-width: 2px;
  transition: 500ms;
`

const BG = styled.div`
  position: absolute;
  z-index: 4;
  background: ${colors.pureBlack};
  opacity: 0.5;
 
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Wipe = styled.div`
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  background: ${colors.pureBlack};

  width: 100vw;
  height: 0%;
`

const Yellow = styled.div`
  background: ${colors.gold};
  width: 100%;
  height: 30vh;
`

const White = styled.div`
  background: ${colors.pureWhite};
  width: 100%;
  height: 30vh;
`

const DiamondStyles = `
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 12vw;

  ${media.tablet} {
    width: 15vw;
  }

  ${media.mobile} {
    width: 40vw;
  }
`

const Video = styled.video`
  ${DiamondStyles}
`

const Image = styled.img`
  ${DiamondStyles}
`