import { useEffect, useMemo } from "react";
import LocomotiveScroll from "locomotive-scroll";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap/all";
import { useMedia } from 'utils/hooks'

gsap.registerPlugin(ScrollTrigger);

const Scroll: React.FC = () => {

  const multiplier = useMedia(1.2, 1.2, 1.2, 1.2)
  const touchMultiplier = useMedia(1.2, 1.2, 1.2, 1.2)
  const lerp = useMedia(0.1, 0.1, 0.1, 0.5)

  const scrollOptions = useMemo(() => ({
    smooth: true,
    getDirection: true,
    multiplier,
    lerp,
    touchMultiplier,
    firefoxMultiplier: 100,
    getSpeed: true,
    resetNativeScroll: true,
    smartphone: {
      smooth: true,
      breakpoint: 428,
    },
    tablet: {
      smooth: true,
      breakpoint: 1024,
    },
  }), [multiplier, touchMultiplier, lerp])

  useEffect(() => {
    const smoothScroll = document.querySelector(".smooth-scroll");

    if (smoothScroll) {
      const locomotiveScroll = new LocomotiveScroll({
        el: document.querySelector(".smooth-scroll"),
        ...scrollOptions,
      });

      locomotiveScroll.update();

      // Exposing to the global scope for ease of use.
      window.locomotiveScroll = locomotiveScroll;

      locomotiveScroll.on("scroll", (obj: any) => {
        window.speed = 0;
        let newSpeed = Math.min(Math.max(obj.speed.toFixed(2), -10), 10);
        if (window.speed !== newSpeed) window.speed = newSpeed;
        
        ScrollTrigger.update()
      });
      locomotiveScroll.on("call", (name: string, dir: string) => {
        const event = new Event(`${name}-${dir}`);
        window.dispatchEvent(event);
      });

      const smoothScroll: HTMLElement | null = document.querySelector(".smooth-scroll");

      ScrollTrigger.scrollerProxy(".smooth-scroll", {
        scrollTop(value: any) {
          return arguments.length ? locomotiveScroll.scrollTo(value, 0, 0) : locomotiveScroll.scroll.instance.scroll.y;
        },
        getBoundingClientRect() {
          return {
            top: 0,
            left: 0,
            width: window.innerWidth,
            height: window.innerHeight,
          };
        },
        pinType: smoothScroll?.style.transform ? "transform" : "fixed",
      });

      ScrollTrigger.addEventListener("refresh", () => locomotiveScroll.update());
      ScrollTrigger.refresh();

      return () => {
        if (locomotiveScroll) locomotiveScroll.destroy();
      };
    }
  }, [scrollOptions]);

  return null;
};

export default Scroll;
