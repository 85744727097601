import React from "react";
import styled from "styled-components";

import colors from "styles/colors";
import text from "styles/text";
import media from "styles/media";

import links from 'utils/links'

import DiscordSVG from "assets/images/icons/discord.svg";
import { useContext } from "react";
import { ScreenContext } from "components/Providers";

type Props = {
  long?: boolean;
};

const Discord: React.FC<Props> = ({ long }) => {

  const { mobile } = useContext(ScreenContext);

  const handleClick = () => {
    window.open(links.discord)
  }

  return (
    <Wrapper long={long} onClick={handleClick}>
      {!long && (
        <SVG viewBox="0 0 226 56">
          <Path d="M 0.5 0.5 H 211 L 225.5 15 V 55.5 H 15 L 0.5 41 Z" />
        </SVG>
      )}

      {long && !mobile && (
        <SVG viewBox="0 0 904 56">
          <Path d="M 0.5 0.5 H 889 L 903.5 15 V 55.5 H 15 L 0.5 41 Z" />
        </SVG>
      )}
      
      {mobile && (
        <SVG viewBox="0 0 345 56">
          <Path d="M 0.5 0.5 H 332 L 344.5 15 V 55.5 H 15 L 0.5 41 Z" />
        </SVG>
      )}

      <Content>
        <DiscordLogo src={DiscordSVG} alt="discord" loading="lazy"/> Join our Discord
      </Content>
    </Wrapper>
  );
};

export default Discord;

const Path = styled.path`
  fill: url(#horizontalGradient);
  transition: 500ms;
`;

const SVG = styled.svg`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  fill: none;
`;

const Content = styled.div`
  ${text.desktop.button}
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.pureWhite};
  position: relative;
  z-index: 10;

  ${media.tablet} {
    ${text.tablet.button}
  }

  ${media.mobile} {
    ${text.mobile.button}
  }
`;

const DiscordLogo = styled.img`
  width: 1.94vw;
  height: 2.36vw;
  margin-right: 1.11vw;

  ${media.tablet} {
    width: 2.73vw;
    height: 3.32vw;
    margin-right: 1.56vw;
  }

  ${media.mobile} {
    margin-right: 4vw;
    width: 6.667vw;
    height: 6.667vw;
  }
`;

const Wrapper = styled.div<{ long?: boolean }>`
  position: relative;
  cursor: pointer;
  transform: translate3d(0, 0, 0);

  &:hover {
    ${Path} {
      fill: url(#reverseHorizontalGradient);
    }
  }

  &:active {
    opacity: 0.8;
  }

  height: 3.89vw;
  width: 15.69vw;

  ${media.tablet} {
    width: ${(props) => (props.long ? "88.28vw" : "19.73vw")};
    height: 5.47vw;
  }

  ${media.mobile} {
    width: 92vw;
    height: 14.933vw;
  }
`;