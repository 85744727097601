import React, { useContext, lazy, Suspense } from 'react'
import { ScreenContext } from 'components/Providers'

const DesktopTablet = lazy(() => import('./DesktopTablet'))
const Mobile = lazy(() => import('./Mobile'))

const Header: React.FC = () => {

  const screen = useContext(ScreenContext)

  return (
    <Suspense fallback={<div/>}>
      {screen.mobile && <Mobile/>}
      {!screen.mobile && <DesktopTablet/>}
    </Suspense>
  )
}

export default Header
