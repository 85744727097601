import React from 'react'
import styled from 'styled-components'

const SVGGradients: React.FC = () => {
  return (
    <Svg>
      <linearGradient id="horizontalGradient" x1="0" x2="1" y1="0" y2="0">
        <stop offset="0%" stopColor="#EEB93C" />
        <stop offset="100%" stopColor="#FF1481" />
      </linearGradient>
      <linearGradient id="verticalGradient" x1="0" x2="0" y1="0" y2="1">
        <stop offset="0%" stopColor="#EEB93C"/>
        <stop offset="100%" stopColor="#FF1481"/>
      </linearGradient>
      <linearGradient id="reverseHorizontalGradient" x1="0" x2="1" y1="0" y2="0">
        <stop offset="0%" stopColor="#FF1481" />
        <stop offset="100%" stopColor="#EEB93C" />
      </linearGradient>
    </Svg>
  )
}

export default SVGGradients

const Svg = styled.svg`
  position: absolute;
  z-index: -1;
`