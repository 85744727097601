
const colors = {
  gold: "#F2BC3D",
  red: "#D93636",
  vertGradient: "linear-gradient(180deg, #EEB93C 0%, #FF1481 100%)",
  horzGradient: "linear-gradient(90deg, #EEB93C 0%, #FF1481 100%)",
  horzReverseGradient: "linear-gradient(90deg, #FF1481 0%, #EEB93C 100%)",
  pureBlack: "#000000", 
  pureWhite: "#FFFFFF",
  black100: "#121212",
  black75: "#292929",
  black50: "#454545",
  black25: "#A7A7A7"
}

export default colors