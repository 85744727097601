import React, { useEffect, useRef, useContext, useState } from "react";
import styled from "styled-components";
import gsap, { SplitText } from "gsap/all";
import { FontsLoadedContext } from "components/Providers";
import { useMedia } from "utils/hooks";

import colors from "styles/colors";
import text from "styles/text";
import media from "styles/media";

import Download from "components/buttons/Download";
import DiscordButton from "components/buttons/Discord";
import VideoCarousel from "components/VideoCarousel";

import ArrowSVG from "assets/images/icons/bitArrow.svg";

import { splitText } from "utils/functions";

type Props = {
  loaded: boolean;
};

const Hero: React.FC<Props> = ({ loaded }) => {
  const buttonLength = useMedia(false, false, true, true);

  const bgClipPath = useMedia("polygon(0 0, 73% 0, 38% 100%, 0% 100%)", "polygon(0 0, 73% 0, 38% 100%, 0% 100%)", "polygon(0 0, 70% 0, -17% 100%, 0% 100%)", "polygon(0 0, 100% 0, 4% 80%, 0% 100%)");

  const fontLoaded = useContext(FontsLoadedContext);

  const accentRef = useRef<HTMLDivElement | null>(null);
  const title1Ref = useRef<HTMLDivElement | null>(null);
  const title2Ref = useRef<HTMLDivElement | null>(null);
  const textRef = useRef<HTMLParagraphElement | null>(null);
  const button1Ref = useRef<HTMLDivElement | null>(null);
  const button2Ref = useRef<HTMLDivElement | null>(null);
  const bgRef = useRef<HTMLDivElement | null>(null);
  const slashRef = useRef<HTMLDivElement | null>(null);

  const [initTl, setInitTl] = useState<GSAPTimeline | null>(null);

  useEffect(() => {
    if (loaded && initTl) {
      initTl.play();
    }
  }, [loaded, initTl]);

  useEffect(() => {
    window.locomotiveScroll.stop()

    if (fontLoaded) {
      const textChild = splitText(textRef.current);
      const titleChild = new SplitText(title1Ref.current, {
        type: "words",
      });

      new SplitText(title1Ref.current, {
        type: "lines",
        linesClass: "parent",
      });

      const tl = gsap.timeline({
        paused: true,
        onComplete: () => {
          window.locomotiveScroll.start()
        }
      });

      tl.from(
        accentRef.current,
        {
          duration: 0.4,
          y: "100%",
        },
        0
      );

      tl.fromTo(
        titleChild.words,
        {
          y: "110%",
        },
        {
          duration: 0.4,
          stagger: 0.2,
          y: "0%",
        },
        0.2
      );

      tl.from(
        textChild.lines,
        {
          duration: 0.4,
          stagger: 0.2,
          y: "100%",
        },
        0.5
      );

      tl.from(
        [button1Ref.current, button2Ref.current],
        {
          duration: 0.5,
          stagger: 0.25,
          y: "105%",
        },
        .8
      );

      tl.set(
        title2Ref.current,
        {
          opacity: 1,
        },
        1
      );

      tl.to(
        slashRef.current,
        {
          duration: 1,
          width: "50vw",
          x: "500%",
        },
        1.3
      );

      tl.set(
        bgRef.current,
        {
          clipPath: bgClipPath,
        },
        1.5
      );

      tl.to(
        title2Ref.current,
        {
          duration: 1,
          x: "-0.59vw",
          y: "1.04vw",
        },
        1.5
      );

      setInitTl(tl);

      return () => {
        tl.kill();
      };
    }
  }, [fontLoaded, bgClipPath, setInitTl]);

  return (
    <Wrapper id="hero-wrapper">

      <VideoCarousel/>
      
      <BG ref={bgRef}>
        {/* <SubText>
          <Hidden ref={accentRef}>
            <Arrow src={ArrowSVG} alt="bit arrow" loading="lazy"/>
            <p>connecting creators & fans</p>
            <Arrow src={ArrowSVG} alt="bit arrow" loading="lazy"/>
          </Hidden>
        </SubText> */}
        <Title ref={title1Ref}>Gaming's best moments</Title>
      </BG>

      <SlashWrapper>
        <Slash ref={slashRef} />
      </SlashWrapper>

      <Title2 ref={title2Ref}>Gaming's Best Moments</Title2>

      <Text ref={textRef}>FreshCut is the home for short form gaming content. Join our fans and creators and get rewarded for engaging with the community and sharing content.</Text>
      <ButtonRow>
        <HiddenWrapper>
          <Hidden ref={button1Ref}>
            <DiscordButton long={buttonLength} />
          </Hidden>
        </HiddenWrapper>

        <HiddenWrapper>
          <Hidden ref={button2Ref}>
            <Download long={buttonLength} />
          </Hidden>
        </HiddenWrapper>
      </ButtonRow>

      <Gradient />
      
      <div style={{fontFamily: 'lores-12'}}/>
      <div style={{fontFamily: 'Monument Extended'}}/>
      <div style={{fontFamily: 'Amina'}}/>
    </Wrapper>
  );
};

export default Hero;

const Wrapper = styled.section`
  width: 100%;
  position: relative;
  transform: translate3d(0, 0, 0);

  height: 65.97vw;

  .vsc-controller {
    display: none;
  }

  ${media.tablet} {
    height: 133.398vw;
  }
  ${media.mobile} {
    height: 190.533vw;
  }
`;

const BG = styled.div`
  background: ${colors.pureBlack};
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  transform: translate3d(0, 0, 0);

  height: 56vw;
  padding-top: 12.99vw;
  padding-left: 4.17vw;
  padding-bottom: 8.54vw;

  ${media.tablet} {
    height: 133.398vw;
    padding-left: 5.859vw;
  }
  ${media.mobile} {
    height: 216.533vw;
    padding: 40vw 4vw 60vw;
  }
`;

const SubText = styled.div`
  ${text.desktop.accent2}
  color: ${colors.pureWhite};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  overflow: hidden;

  .parent {
    overflow: hidden;
  }

  margin-bottom: 1.48vw;

  p {
    margin-right: 0.35vw;
    margin-left: 0.35vw;
  }

  ${media.tablet} {
    position: absolute;
    left: 5.859vw;
    top: 34.18vw;

    p {
      ${text.tablet.accent2}
    }
  }

  ${media.mobile} {
    p {
      ${text.mobile.accent3}
    }
  }
`;

const Arrow = styled.img`
  transform: rotate(-90deg);
  transform-origin: center;

  height: 1.27vw;
  width: 1.08vw;

  ${media.tablet} {
    width: 1.789vw;
    height: 1.514vw;
  }

  ${media.mobile} {
    width: 3.733vw;
    height: 2.933vw;
  }
`;

const TitleStyles = `
  ${text.desktop.h2}
  position: absolute;
  z-index: 5;
  text-transform: uppercase;
  transform: translate3d(0, 0, 0);
  color: ${colors.pureWhite};
  
  .parent { 
    overflow: hidden;
  }

  width: 94vw;
  top: 15.63vw;
  left: 4.17vw;

  ${media.tablet} {
    ${text.tablet.h2}
    width: 91.993vw;
    height: 35.156vw;
    top: 37.891vw;
  }

  ${media.mobile} {
    ${text.mobile.h1};
    width: 94vw;
    height: 40vw;
    top: 48.8vw;
  }
`;

const Title = styled.h1`
  ${TitleStyles}
`;

const Title2 = styled.div`
  ${TitleStyles}
  z-index: 1;
  ${text.style.outline}
  opacity: 0;
`;

const Text = styled.p`
  ${text.desktop.bodyR}
  color: ${colors.pureWhite};
  position: absolute;
  transform: translate3d(0, 0, 0);
  z-index: 3;

  .parent {
    overflow: hidden;
  }

  left: 4.17vw;
  top: 33.68vw;
  width: 40.28vw;

  ${media.tablet} {
    ${text.tablet.bodyL}
    width: 63.477vw;
    height: 16.406vw;
    top: 75.977vw;
  }

  ${media.mobile} {
    ${text.mobile.bodyR};
    width: 92vw;
    top: 96.8vw;
    left: 4vw;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  transform: translate3d(0, 0, 0);
  z-index: 3;

  width: 33.75vw;
  left: 4.17vw;
  top: 43.13vw;

  ${media.tablet} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 101.953vw;
    width: 88.281vw;
    gap: 1.953vw;
    left: 50%;
    transform: translateX(-50%);
  }

  ${media.mobile} {
    z-index: 6;
    gap: 5.333vw;
    top: 142.667vw;
    width: 92vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const Gradient = styled.div`
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 80%);
  width: 100vw;
  position: absolute;
  transform: translate3d(0, 0, 0);
  left: 0;
  z-index: 1;

  bottom: -1vw;
  height: 50.78vw;

  ${media.tablet} {
    top: 79.688vw;
    bottom: unset;
    height: 53.711vw;
  }

  ${media.mobile} {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 65%);
    top: 63vw;
    bottom: unset;
    height: 160.333vw;
  }
`;

const Hidden = styled.div`
  display: flex;
  align-items: center;
`;

const HiddenWrapper = styled.div`
  overflow: hidden;
`;

const SlashWrapper = styled.div`
  position: absolute;
  z-index: 5;
  top: 40vw;
  right: 2vw;
  width: 100vw;
  height: 5px;
  transform: rotate(122deg);

  ${media.tablet} {
    right: 6.5vw;
    transform: rotate(123deg);
  }
  ${media.mobile} {
    right: -27vw;
    transform: rotate(1199deg);
  }
`;

const Slash = styled.div`
  height: 2px;
  width: 0vw;
  background: yellow;
  filter: blur(1px);
`;
