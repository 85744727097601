import { SplitText } from 'gsap/all'

export const vwToPx = (vw: number) => {
  const px = vw * (window.innerWidth / 100)
  return px
}

export const zeroPad = (num: number) => {
  return (`0${num}`).slice(-2)
}

export const splitText = (ref: any) => {
  
  let child = new SplitText(ref, {
    type: 'lines'
  })
  
  new SplitText(ref, {
    type: 'lines',
    linesClass: 'parent'
  })

  return child
}

export const isSafari = () => {
  return window.safari !== undefined;
}

export const getQueryVariable = (variable: string) => {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i=0;i<vars.length;i++) {
    var pair = vars[i].split("=");
    if(pair[0] === variable) {
      return pair[1];
    }
  }
}