// export const firebaseConfig = {
//   apiKey: "AIzaSyA7932c7-owFEuQX3JGKE8VIhLFVSAfqiQ",
//   authDomain: "freshcut-diamond-dev.firebaseapp.com",
//   projectId: "freshcut-diamond-dev",
//   storageBucket: "freshcut-diamond-dev.appspot.com",
//   messagingSenderId: "786817548505",
//   appId: "1:786817548505:web:ffe3038c775dd4ec25fec8",
//   measurementId: "G-W7Y4B33TQM"
// };

export const firebaseConfig = {
  apiKey: "AIzaSyAqb4KvvX_49YSR3LjZMgVKGwE_iruIMUg",
  authDomain: "freshcut-diamond-ba6d9.firebaseapp.com",
  projectId: "freshcut-diamond-ba6d9",
  storageBucket: "freshcut-diamond-ba6d9.appspot.com",
  messagingSenderId: "307948233590",
  appId: "1:307948233590:web:554cf845401e9478181057",
  measurementId: "G-JFFKZ2YW7Z"
};