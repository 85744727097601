import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Providers from "components/Providers";
import {initializeApp} from 'firebase/app';
import { firebaseConfig } from "firebaseConfig";

initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <App />
    </Providers>
  </React.StrictMode>,
  document.getElementById("root")
);
