import colors from "styles/colors";

const text = {
  style: {
    gradient: `
      background: ${colors.horzGradient};
      text-fill-color: transparent;
      -moz-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-text-fill-color: transparent;
      background-clip: text;
      -webkit-background-clip: text;
    `,
    outline: `
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: ${colors.pureWhite};
    `,
  },
  fullWidth: {
    h1: `
      font-family: Monument Extended;
      font-style: normal;
      font-weight: bold;
      font-size: 300px;
      line-height: 100%;
    `,
    h2: `
      font-family: Monument Extended;
      font-style: normal;
      font-weight: bold;
      font-size: 120px;
      line-height: 100%;
    `,
    h3: `
      font-family: Monument Extended;
      font-style: normal;
      font-weight: bold;
      font-size: 90px;
      line-height: 100%;
    `,
    h4: `
      font-family: Monument Extended;
      font-style: normal;
      font-weight: bold;
      font-size: 60px;
      line-height: 100%;
    `,
    h5: `
      font-family: Monument Extended;
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 110%;
    `,
    h6: `
      font-family: Monument Extended;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 110%;
    `,
    bodyL: `
      font-family: Amina;
      font-style: normal;
      font-weight: normal;
      font-size: 30px;
      line-height: 140%;
      letter-spacing: 0.01em;
    `,
    bodyR: `
      font-family: Amina;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 140%;
      letter-spacing: 0.01em;
    `,
    bodyS: `
      font-family: Amina;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 160%;
      letter-spacing: 0.01em;
    `,
    button: `
      font-family: Amina;
      font-style: normal;
      font-weight: bold;
      font-size: 17px;
      line-height: 120%;
      letter-spacing: 0.01em;
    `,
    accent1: `
      font-family: lores-12, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 45px;
      line-height: 140%;
      letter-spacing: -0.04em;
    `,
    accent2: `
      font-family: lores-12, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 90%;
    `,
    accent3: `
      font-family: lores-12, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 90%;
    `,
  },
  desktop: {
    h1: `
      font-family: Monument Extended;
      font-style: normal;
      font-weight: bold;
      font-size: 20.833vw;
      line-height: 100%;
    `,
    h2: `
      font-family: Monument Extended;
      font-style: normal;
      font-weight: bold;
      font-size: 8.333vw;
      line-height: 100%;
    `,
    h3: `
      font-family: Monument Extended;
      font-style: normal;
      font-weight: bold;
      font-size: 6.25vw;
      line-height: 100%;
    `,
    h4: `
      font-family: Monument Extended;
      font-style: normal;
      font-weight: bold;
      font-size: 4.167vw;
      line-height: 100%;
    `,
    h5: `
      font-family: Monument Extended;
      font-style: normal;
      font-weight: bold;
      font-size: 2.083vw;
      line-height: 110%;
    `,
    h6: `
      font-family: Monument Extended;
      font-style: normal;
      font-weight: bold;
      font-size: 1.389vw;
      line-height: 110%;
    `,
    bodyL: `
      font-family: Amina;
      font-style: normal;
      font-weight: normal;
      font-size: 2.083vw;
      line-height: 140%;
      letter-spacing: 0.01em;
    `,
    bodyR: `
      font-family: Amina;
      font-style: normal;
      font-weight: normal;
      font-size: 1.389vw;
      line-height: 140%;
      letter-spacing: 0.01em;
    `,
    bodyS: `
      font-family: Amina;
      font-style: normal;
      font-weight: normal;
      font-size: 1.042vw;
      line-height: 160%;
      letter-spacing: 0.01em;
    `,
    button: `
      font-family: Amina;
      font-style: normal;
      font-weight: bold;
      font-size: 1.181vw;
      line-height: 120%;
      letter-spacing: 0.01em;
    `,
    accent1: `
      font-family: lores-12, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 3.125vw;
      line-height: 140%;
      letter-spacing: -0.04em;
    `,
    accent2: `
      font-family: lores-12, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 1.389vw;
      line-height: 90%;
    `,
    accent3: `
      font-family: lores-12, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 1.042vw;
      line-height: 90%;
    `,
  },
  tablet: {
    h1: `
      font-family: Monument Extended;
      font-style: normal;
      font-weight: 700;
      font-size: 23.44vw;
      line-height: 100%;
    `,
    h2: `
      font-family: Monument Extended;
      font-style: normal;
      font-weight: 700;
      font-size: 11.719vw;
      line-height: 100%;
    `,
    h3: `
      font-family: Monument Extended;
      font-style: normal;
      font-weight: 700;
      font-size: 8.79vw;
      line-height: 100%;
    `,
    h4: `
      font-family: Monument Extended;
      font-style: normal;
      font-weight: 600;
      font-size: 5.859vw;
      line-height: 100%;
    `,
    h5: `
      font-family: Monument Extended;
      font-style: normal;
      font-weight: 700;
      font-size: 2.93vw;
      line-height: 110%;
    `,
    h6: `
      font-family: Monument Extended;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 115%;
    `,
    bodyL: `
      font-family: Amina;
      font-style: normal;
      font-weight: 400;
      font-size: 2.93vw;
      line-height: 140%;      
      letter-spacing: 0.01em;
    `,
    bodyR: `
      font-family: Amina;
      font-style: normal;
      font-weight: 400;
      font-size: 1.95vw;
      line-height: 140%;
      letter-spacing: 0.01em;
    `,
    bodyS: `
      font-family: Amina;
      font-style: normal;
      font-weight: 400;
      font-size: 1.46vw;
      line-height: 160%;      
      letter-spacing: 0.01em;
    `,
    button: `
      font-family: Amina;
      font-style: normal;
      font-weight: 700;
      font-size: 1.66vw;
      line-height: 120%;      
      text-align: center;
      letter-spacing: 0.01em;
    `,
    accent1: `
      font-family: lores-12, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 4.39vw;
      line-height: 140%;
      letter-spacing: -0.04em;
    `,
    accent2: `
      font-family: lores-12, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 1.95vw;
      line-height: 90%;
    `,
    accent3: `
      font-family: lores-12, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 1.46vw;
      line-height: 90%;
    `,
  },
  mobile: {
    h1: `
      font-family: 'Monument Extended';
      font-style: normal;
      font-weight: 700;
      font-size: 13.333vw;
      line-height: 100%;  
      letter-spacing: -0.07em;
      text-transform: uppercase;
    `,
    h2: `
      font-family: Monument Extended;
      font-style: normal;
      font-weight: bold;
      font-size: 32vw;
      line-height: 100%;
    `,
    h3: `
      font-family: Monument Extended;
      font-style: normal;
      font-weight: bold;
      font-size: 24vw;
      line-height: 100%;
    `,
    h4: `
      font-family: Monument Extended;
      font-style: normal;
      font-weight: bold;
      font-size: 16vw;
      line-height: 100%;
    `,
    h5: `
      font-family: Monument Extended;
      font-style: normal;
      font-weight: bold;
      font-size: 8vw;
      line-height: 110%;
    `,
    h6: `
      font-family: Monument Extended;
      font-style: normal;
      font-weight: bold;
      font-size: 5.333vw;
      line-height: 115%;
    `,

    bodyL: `
      font-family: Amina;
      font-style: normal;
      font-weight: normal;
      font-size: 8vw;
      line-height: 140%;
      letter-spacing: 0.01em;
    `,

    bodyR: `
      font-family: Amina;
      font-style: normal;
      font-weight: normal;
      font-size: 5.333vw;
      line-height: 140%;
      letter-spacing: 0.01em;
    `,

    bodyS: `
      font-family: Amina;
      font-style: normal;
      font-weight: normal;
      font-size: 4vw;
      line-height: 160%;
      letter-spacing: 0.01em;
  `,

    accent1: `
      font-family: lores-12, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12vw;
      line-height: 140%;
      letter-spacing: -0.04em;
      `,

    accent2: `
      font-family: lores-12, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 5.333vw;
      line-height: 90%;
    `,

    accent3: `
      font-family: lores-12, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 4vw;
      line-height: 150%;
    `,

    button: `
      font-family: Amina;
      font-style: normal;
      font-weight: bold;
      font-size: 4.533vw;
      line-height: 120%;
      letter-spacing: 0.01em;
    `,
  },
};

export default text;
