import React, { useState, lazy, Suspense, useEffect } from "react";
import styled from "styled-components";

import colors from "styles/colors";

import Scroll from "components/Scroll";
import Cursor from "components/Cursor";
import Header from "components/Header";
import Preloader from "components/Preloader";
import SVGGradients from "components/SvgGradients";
import Hero from "sections/01-Hero";

import { getAnalytics, logEvent } from "firebase/analytics";

const Reveal = lazy(() => import("sections/02-Reveal"));
const Powers = lazy(() => import("sections/04-Powers"));
const Earn = lazy(() => import("sections/05-Earn"));
const Utility = lazy(() => import("sections/06-Utility"));
const Platform = lazy(() => import("sections/07-Platform"));
const Roadmap = lazy(() => import("sections/08-Roadmap"));
const SocialProof = lazy(() => import("sections/09-SocialProof"));
const MultipleBanners = lazy(() => import("sections/10-MultipleBanners"));
const Community = lazy(() => import("sections/11-Community"));
const CTA = lazy(() => import("sections/12-CTA"));

const App: React.FC = () => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const analytics = getAnalytics();
  useEffect(() => {
    if (analytics) {
      logEvent(analytics, "page_view");
    }
  }, [analytics]);

  return (
    <Wrapper>
      <Preloader setLoaded={setLoaded} />
      <Header />
      <Scroll />
      <Cursor />
      <SVGGradients />
      <ScrollContainer className="smooth-scroll">
        <Hero loaded={loaded} />
        <Suspense fallback={<div />}>
          <Reveal loaded={loaded} />
        </Suspense>
        <Suspense fallback={<div />}>
          <Powers />
        </Suspense>
        <Suspense fallback={<div />}>
          <Earn />
        </Suspense>
        <Suspense fallback={<div />}>
          <Utility />
        </Suspense>
        <Suspense fallback={<div />}>
          <Platform />
        </Suspense>
        <Suspense fallback={<div />}>
          <Roadmap />
        </Suspense>
        <Suspense fallback={<div />}>
          <SocialProof />
        </Suspense>
        <Suspense fallback={<div />}>
          <MultipleBanners />
        </Suspense>
        <Suspense fallback={<div />}>
          <Community />
        </Suspense>
        <Suspense fallback={<div />}>
          <CTA />
        </Suspense>
      </ScrollContainer>
    </Wrapper>
  );
};

export default App;

const Wrapper = styled.main`
  background: ${colors.pureBlack};
  min-height: 100vh;
`;

const ScrollContainer = styled.div`
  width: 100%;
  will-change: transform;
`;
