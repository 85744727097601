import { useContext, useEffect } from "react";
import { ScreenContext } from "components/Providers";
import gsap from 'gsap/all'

export const useStaggeredMovement = (refs: any[]) => {
  useEffect(() => {
    if (refs) {
      const mouseMove = (e: MouseEvent) => {
        gsap.to(refs, {
          stagger: 0.03,
          x: -e.clientX / 30,
          y: -e.clientY / 30,
        });
      };

      window.addEventListener("mousemove", mouseMove);

      return () => {
        window.removeEventListener("mousemove", mouseMove);
      };
    }
  }, [refs]);
};

export const useMedia = (fw: any, d: any, t: any, m: any) => {
  const screen = useContext(ScreenContext);

  if (screen.fullWidth) {
    return fw;
  } else if (screen.desktop) {
    return d;
  } else if (screen.tablet) {
    return t;
  } else if (screen.mobile) {
    return m;
  }
};
